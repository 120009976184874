<template>
    <v-app light >
        <!-- <loading v-if="loading" /> -->
        <v-container>
            <v-card flat>
                <v-container>
                    <v-row align="center" justify="center" class="mx-4">
                        <v-col>
                            <!-- <v-avatar tile size='150' class="mt-1"> -->
                            <img :src="$config.logo" width="150px"/>
                            <!-- </v-avatar> -->
                        </v-col>
                        <v-col class="text-center">
                            <v-row dir="ltr" justify="center" align="center">
                                <qr-code :size="80" text="currentItem.no"></qr-code>
                            </v-row>
                        </v-col>
                        <v-col class="text-end">
                            <h3>
                                <strong>
                                    كشف حساب
                                </strong>
                            </h3>
                            <!-- <h3>12312</h3> -->
                            <!-- <span class="grey--text">{{currentItem.lockedAt.substr(0,10)}} - {{ formatDate(currentItem.lockedAt) }}</span> -->
                        </v-col>
                        <v-col cols="12">
                            <v-divider />
                        </v-col>
                        
                        <v-col cols="12">
                            <v-divider style="border: dashed 1px #A3A3A3"/>
                        </v-col>
                        <v-col cols="12">
                            <table id="orders" style="width: 100%">
                                <thead>
                                    <tr>
                                        <th>
                                            ت
                                        </th>
                                        <th>
                                            الاسم
                                        </th>
                                        <th>
                                            رقم الهاتف
                                        </th>
                                        <th>
                                            المحافظة
                                        </th>
                                        <th>
                                            المنطقة
                                        </th>
                                        <th>
                                            رقم الشحنة
                                        </th>
                                        <th>
                                            المبلغ
                                        </th>
                                        <th>
                                            النوع
                                        </th>
                                        <th>
                                            المبلغ (المستلم/المسدد)
                                        </th>
                                        <th>
                                            المبلغ المتبقي
                                        </th>
                                        <th>
                                            تاريخ (الاستلام / الدفع)
                                        </th>
                                        <th>
                                            تاريخ الادخال
                                        </th>
                                        <th>
                                            الحالة
                                        </th>
                                        <th>
                                            ملاحظات
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr v-for="(item,index) in currentItem.orders" :key="index">
                                        <td>{{item.no}}</td>
                                        <td>{{item.merchant.fullName}}</td>
                                        <td>{{item.packageItemsCount}}</td>
                                        <td>{{$service.formatCurrency(item.packageCost)}}</td>
                                        <td dir="ltr" style="text-align: right">
                                            {{item.customerPhoneNumber}} 
                                            <br/>
                                            {{ item.customerPhoneNumber2 }}
                                        </td>
                                        <td>{{item.deliveryGovernorate.name}}</td>
                                        <td>{{item.deliveryAddress}}</td>
                                        <td></td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </v-col>
                        <v-col cols="12">
                            <v-divider style="border: dashed 1px #A3A3A3"/>
                        </v-col>
    
                        <v-col cols="6">
                            <v-card outlined style="border: dashed 1px #A3A3A3">
                                <v-container>
                                    <h4>التوقيع</h4>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-card outlined style="border: dashed 1px #A3A3A3">
                                <v-container>
                                    <h4>ملاحظات</h4>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-container>
    </v-app>
    </template>
    
    <script>
    import moment from 'moment';
    export default {
        props: ["currentItem"],
        data() {
            return {
                loading: false,
                // logo: "",
            }
        },
    
        methods: {
            formatDate(date) {
                return moment(date).format('h:mm:ss a');
            },
        },
    }
    </script>
    
    